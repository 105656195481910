.product {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;

  &__title {
    color: #011210;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 33.6px */
    span {
      display: inline-flex !important;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      background: #C6ECE8;
      margin-right: 10px;
      cursor: pointer;
    }

  }

  &__block {
    display: flex;
    gap: 20px;

    & > div {
      padding: 20px;
      background: white;
      border-radius: 20px;
    }

    .product__photo {
      max-width: 400px;
      min-width: 400px;

      img {
        border-radius: 10px;
        width: 100%;
      }
    }
  }

  &__price {
    width: 100%;
    margin-top: 20px;

    &--title {
      color: #0CE3CB;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 24px */
    }

    &--block {
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        display: flex;
      }

      span:nth-child(3) {
        color: #0CE3CB;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.5px;
      }

      span:nth-child(2) {
        background: #0CE3CB;
        padding: 3px;
        border-radius: 4px;

        color: #FFF;
        height: fit-content;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      span:nth-child(1) {
        opacity: 0.65;
        color: #888;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: line-through;
        text-decoration-thickness: 2px;
      }
    }
  }

  &__btns {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 10px;
      padding: 14px 0;
      margin-top: 20px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
    }

    .favorite {
      color: #067c6f;
      font-size: 16px;
      background: rgba(198, 236, 232, 0.68);
    }

    .cart {
      border-radius: 10px;
      background: #0CE3CB;
      color: white;
    }


  }

  &__description {
    width: 100%;

    span {
      display: inline;
    }

    ul {
      display: flex;
      gap: 10px;
      margin-top: 10px;
      flex-wrap: wrap;
    }

    li {
      padding: 10px;
      background: rgba(128, 126, 126, 0.13);
      list-style: none;
      width: fit-content;
      border-radius: 10px;
      font-size: 12px;
    }
  }

}

.form {
  display: flex;
  border-radius: 6px;
  background: #F1F0EC;
  padding: 14px 10px;
  width: 220px;

  justify-content: space-around;
  align-items: center;

  div {
    display: flex;
    align-items: center;


  }
}


.isCart {
  display: flex;
  gap: 20px;
  margin-top: 20px;

  .toCart {
    width: 100%;
    border-radius: 10px;
    padding: 14px 0;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #0aa493;
    color: white;
    cursor: pointer;
  }

  .plus, .minus {
    cursor: pointer;

    img {
      width: 14px !important;
    }
  }

  .minus {
    padding: 8px 0;
  }


}

@media screen and (max-width: 800px) {

  .product__block {
    flex-direction: column;

    .product__photo {
      max-width: 100%;
      width: 100%;
      display: flex;
      min-width: 100%;
      flex-direction: column;

      & > img {
        max-width: 500px;
        align-self: center;

      }
    }


  }


  @media screen and (max-width: 400px) {
    .isCart {
      margin-top: 10px;

      & > div {
      }

    }
  }


}

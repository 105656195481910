.barMobile{
  display: flex;
  position: sticky;
  width: 100%;
  background: white;
  justify-content: space-between;
  padding: 10px 20px;
  bottom: 0 !important;
  z-index: 1000;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 -2px 30px 0 rgba(0, 0, 0, 0.10);
  margin-top: 20px;

  @media screen and (min-width: 800px) {
    display: none;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, serif;
}

html, body {
  background: #f7f7f7;
}

.container {
  max-width: 1400px;
  margin: 0 auto;

  width: 100%;
  @media (max-width: 1400px) {
    padding: 0 16px;
  }
}

.main {
  margin-top: 40px;
  @media (max-width: 800px) {
    margin-top: 20px;
  }
}


.notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;

  @media screen and (max-width: 600px) {
    height: 80vh;
    img {
      width: 80%;
    }
  }
}

a {
  text-decoration: none;
}

.cart {
  display: flex;
  gap: 40px;

  button {
    border-radius: 10px;
    background: var(--color-turquoise, #0CE3CB);
    border: none;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 20px;
    margin-top: 10px
  }

  & > div {
    width: 50%;
  }


  &__products {

    & > div > div {
      display: flex !important;
      flex-wrap: nowrap;
      overflow-x: auto !important;
      overflow-y: hidden;
      gap: 1px;

      &:nth-child(2) {
        background: white;
        border-radius: 10px;
      }


      & > div {
        min-width: 180px !important;
        max-width: 200px;
        @media screen and (max-width: 600px) {
          min-width: 180px !important;
        }

      }
    }
  }

  &__right {
  }

  &__title {
    color: var(--logo-black, #060606);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 40px;

    letter-spacing: -0.3px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;

    input {
      border-radius: 15px;
      border: 1.5px solid #011210;
      background: #FFF;
      padding: 12px 32px;

      color: var(--logo-black, #060606);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;


    }
  }

  &__select {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--title {
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &--block {
      display: flex;
      gap: 10px;
    }

    &--item {
      border-radius: 10px;
      background: #FFF;
      width: 100%;
      padding: 24px;
      display: flex;
      align-items: center;
      gap: 10px;
      border: 1.5px solid #fff;
      transition: .2s;

      color: var(--logo-black, #060606);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      position: relative;

      input {
        display: none;
      }

      .indicator {
        width: 32px;
        height: 32px;
        background: #F1F0EC;
        border-radius: 5px;
        position: absolute;
        right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .5s;


        img {
          height: 0;
          width: 0;
          transition: .3s;
        }
      }

      &:has(input[type="radio"]:checked) {
        border: 1.5px solid #011210;

        .indicator {
          background: #0CE3CB;

          img {
            width: 21.333px;
            height: 21.333px
          }
        }
      }
    }
  }

  &__total {
    margin-top: 10px;
    padding: 10px;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &--item {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }


}

.error {
  color: red;
  font-weight: 600;
}


@media (max-width: 1200px) {
  .error {
    font-weight: 400;
    font-size: 12px;
    margin-top: -5px;
  }

  .cart {
    flex-direction: column-reverse;
    gap: 0px;

    & > div {
      width: 100%;
    }

    @media (max-width: 600px) {
      .cart__select--item {
        padding: 10px;
        font-size: 14px;
      }

      @media (max-width: 600px) {
        .cart__select--block {
          flex-direction: column;
        }

        .cart__total--item {
          font-size: 14px;
        }

        .cart__total--sub {
          font-size: 14px;
        }
      }
    }
  }


}

.cart__empty {
  width: 100% !important;
  height: 70vh;
  display: flex;
  justify-content: center;align-items: center;
}



.h100 {
  min-height: 80vh;
}

.title {
  margin-top: 40px;
  margin-bottom: 24px;
}

.show_more {
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  border: 2px solid #0CE3CB;
  color: rgba(12, 227, 203);
  background: #fff;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  display: flex;
  margin-top: 10px;
  @media (max-width: 600px) {
    padding: 15px;

  }
}

.ant-menu-title-content, td>span, th>span {
  display: block !important; /* Принудительно отображаем текст меню */
}

.ant-card {
  word-break: break-word; /* Позволяет тексту переноситься */
}

.ant-table {
  overflow-x: auto; /* Добавляем скроллинг для таблиц на мобильных устройствах */
}

.ant-statistic-title {
  font-size: 14px; /* Чуть уменьшаем размер текста для мобильных экранов */
}

/* Общий стиль для адаптивности */
@media (max-width: 768px) {
  .ant-card {
    margin-bottom: 16px; /* Увеличиваем отступы между карточками */
  }

  .ant-card-head-title {
    //font-size: 16px; /* Уменьшаем заголовки карточек для мобильных экранов */
  }

  .ant-select {
    width: 100% !important; /* Переписываем ширину для select-компонента */
  }

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    font-size: 14px; /* Уменьшаем размер текста в таблицах */
  }

  .ant-row {
    flex-wrap: wrap; /* Автоматическое перенесение содержимого в строках */
  }

  .ant-col {
    width: 100% !important; /* Каждая колонка на 100% ширины */
  }
}

div.ant-col-xs-24 span {
  display: inline-block !important;
}
.orders-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
}

.filter-section {
  margin-bottom: 24px;
}

.order-card {
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.order-card:hover {
  transform: translateY(-4px);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}

.order-card .ant-btn-primary {
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .orders-page-container {
    padding: 12px;
  }
  .filter-section .ant-col {
    margin-bottom: 12px;
  }
}


.ads{
  margin-top: 40px;
  padding: 25px;
  background: #0CE3CB;
  border-radius: 25px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  &__title{
    font-size: 30px;
    font-weight: 800;
  }

  &__sub{
    font-weight: 800;
    font-size: 20px;
  }

  &__btn{
    padding: 15px 20px;
    border: 4px solid #FFF;
    font-weight: 800;
    color: #fff;
    font-size: 20px;
  }
}
